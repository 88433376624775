import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Select from "react-select";
import styles from "./MySelect.module.scss";

const MySelect = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: meta.touched && meta.error ? "red" : base.borderColor,
      "&:hover": {
        borderColor: meta.touched && meta.error ? "red" : base.borderColor,
      },
      boxShadow: state.isFocused
        ? meta.touched && meta.error
          ? "0 0 0 1px red"
          : "0 0 0 1px #2684ff"
        : "none",
    }),
  };

  return (
    <div className={`${styles.container} ${styles.flex}`}>
      <label className={styles.label}>{label}</label>
      <Select
        {...field}
        {...props}
        className={styles.select}
        options={options}
        styles={customStyles}
        placeholder={"Вибрати..."}
        onChange={(selectedOption) => setValue(selectedOption)}
      />
      {meta.touched && meta.error ? (
        <div className={styles.errorMessage}>{meta.error}</div>
      ) : null}
    </div>
  );
};

MySelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default MySelect;
