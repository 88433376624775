import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useMessageSuccess,
  useMessageError,
} from "../../../../hooks/message.hook";
import { Formik, Form, Field } from "formik";
import { placeOrderNoAuth } from "../../../../http/ordersAPI";
import schema from "./schema"; // Імпорт схеми валідації
import MyTextInput from "../../../../components/MyForms/MyTextInput/MyTextInput";
import styles from "./QuickOrder.module.scss";
import { Button } from "react-bootstrap";
import CustomerDataInputs from "../../CustomerDataInputs/CustomerDataInputs";
import DeliveryDataInputs from "../../DeliveryDataInputs/DeliveryDataInputs";
import PaymentDataInputs from "../../PaymentDataInputs/PaymentDataInputs";
import { getUserIsLoadingSelector } from "../../../../store/user/selectors";
import {
  cartTotalPriceAction,
  saveCartAction,
  saveLocalCartAction,
} from "../../../../store/cart/actions";
import { getLocalCartSelector } from "../../../../store/cart/selectors";

const QuickOrder = () => {
  const [messageServer, setMessageServer] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commentAvailable, setCommentAvailable] = useState(false);
  const customerLoading = useSelector(getUserIsLoadingSelector);
  const messageSuccess = useMessageSuccess();
  const messageError = useMessageError();
  const [products, setProducts] = useState([]);
  let localCart = useSelector(getLocalCartSelector);

  useEffect(() => {
    if (localCart.length) {
      setProducts(localCart);
    } else {
      navigate("/");
    }
  }, [localCart]);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phone: "+380",
        email: "",
        region: null,    // Об'єкт { value, label }
        city: null,      // Об'єкт { value, label }
        delivery: { value: "Новою поштою", label: "Новою поштою" },
        address: null,   // Об'єкт { value, label }
        paymentMethod: "MonoPay",
        comment: "",
      }}
      validationSchema={schema} // Додано схему валідації
      onSubmit={(values, { setSubmitting }) => {
        // Перетворюємо об'єкти на `label` перед відправкою
        const deliveryAddress = {
          delivery: values.delivery?.label || "",
          region: values.region?.label || "",
          city: values.city?.label || "",
          address: values.address?.label || "",
        };

        const ordersValue = {
          ...values,
          delivery: values.delivery?.label || "",
        };

        placeOrderNoAuth({ ...ordersValue, deliveryAddress, products })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              messageSuccess("Замовлення успішно оформлене!");
              if (res.data.data["pageUrl"]) {
                localStorage.setItem("cart", JSON.stringify([]));
                window.location.href = res.data.data["pageUrl"];
                dispatch(cartTotalPriceAction(0));
                dispatch(saveCartAction([]));
                dispatch(saveLocalCartAction([]));
                return;
              } else {
                navigate("orderAccepted");
                localStorage.setItem("cart", JSON.stringify([]));
                dispatch(cartTotalPriceAction(0));
                dispatch(saveCartAction([]));
                dispatch(saveLocalCartAction([]));
              }
            }
          })
          .catch((err) => {
            const errorMessage = err?.data
              ? Object.values(err.data).join("")
              : "Виникла помилка при оформленні замовлення";
            setMessageServer(<span>{errorMessage}</span>);
            messageError(err.data.message);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {(formik) => (
        <Form>
          <div className={styles.tabContainer}>
            <CustomerDataInputs formik={formik} />
            <h3 className="checkout__title">Доставка</h3>
            <DeliveryDataInputs />
            <hr />
            <h3 className="checkout__title">Оплата</h3>
            <PaymentDataInputs />
            <hr />
            <div className={styles.addComment}>
              {!commentAvailable && (
                <span
                  type="button"
                  className={styles.comment}
                  id="commentInvolver"
                  onClick={() => setCommentAvailable(true)}
                >
                  Додати коментарій
                </span>
              )}
              {commentAvailable && (
                <div className={styles.textareaContainer}>
                  <label className={styles.label}>Коментарій</label>
                  <Field
                    as="textarea"
                    className={styles.textarea}
                    name="comment"
                    placeholder="Коментарій"
                    rows={5}
                  />
                </div>
              )}
            </div>
            <Button variant="dark" type="submit" disabled={!products.length}>
              Оформити замовлення
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default QuickOrder;
