import { Navigate, Route, Routes } from "react-router-dom";
import Page404 from "../pages/Page404/Page404";
import Auth from "../pages/Auth/Auth";
import Product from '../pages/Products/Product/Product';
import Transaction from "../pages/Admin/Transaction/Transaction";
import Products from "../pages/Products/Products";
import AccountClient from "../pages/Customer/Account/Account";
import Account from "../pages/Admin/Account/Account";
import Gamefiacation from "../pages/Customer/Gamefication/Gamefication";
import Statistic from "../pages/Admin/Statistic/Statistic";
import Index from "../pages/Index/Index";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
// import PermissionService from "../services/Permission.service";
import Roles from "../pages/Admin/Roles/Roles";
import Categories from "../pages/Admin/Categories/Categories";
import AdminProducts from "../pages/Admin/Products/Products";
import Brands from "../pages/Admin/Brand/Brand";
import Users from "../pages/Admin/Users/Users";
import Wishlish from "../pages/Admin/WishList/WishList";
import WishlistClient from "../pages/Customer/WishList/WishList";
import RegistrationSuccess from "../pages/Auth/RegistrationSuccess/RegistrationSuccess";
import Discounts from "../pages/Admin/Discount/Discounts";
import Blog from "../pages/Admin/Blog/Blog";
import BlogOpen from "../pages/Blog/Blog";
import FormWrapper from "../pages/Admin/Blog/FormWrapper/FormWrapper";
import Statuses from "../pages/Admin/Statuses/Statuses";
import Order from "../pages/Orders/Orders";
import AdminProductsGroups from "../pages/Admin/ProductsGroup/ProductsGroups";
import BlogPagetoOne from "../pages/Blog/BlogPagetoOne/BlogPagetoOne";
import Orders from "../pages/Admin/Orders/Orders";
import OrdersFormWrapper from "../pages/Admin/Orders/FormWrapper/FormWrapper";
import OrderAcceptedPage from "../pages/Orders/OrderAcceptedPage/OrderAcceptedPage";
import OrdersAdmin from "../components/OrdersAdmin/OrdersAdmin";
import RolesFormWrapper from "../pages/Admin/Roles/FormWrapper/FormWrapper";
import OrdersCustomer from "../pages/Customer/OrderCustomer/OrdersCustomer";
import ConfirmRegistration from "../pages/Auth/ConfirmRegistration/ConfirmRegistration";
import CarouselSettings from "../pages/Admin/Carusels/CarouselSettings";
import ContractPage from "../pages/ContractPage/ContractPage";
import AboutUs from "../pages/AboutUs/AboutUs";

const AppRoutes = () => {
    const auth = useContext(AuthContext);
    const openRoutes = (<>
        <Route path="/" element={<Navigate to="/index" />} />
        <Route path='/index' element={<Index/>}/>
        <Route path='/blog' element={<BlogOpen/>}/>
        <Route path='/blog/:id' element={<BlogPagetoOne/>}/>
        <Route path='/products/item/:id' element={<Product />} />
        <Route path='/products/categories/:categories/:filters' element={<Products/>}/>
        <Route path='/products/categories/:categories' element={<Products/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/orders' element={<Order />}/>
        <Route path='/orders/orderAccepted' element={<OrderAcceptedPage />}/>
        <Route path='/confirm-registration/:token' element={<ConfirmRegistration />}/>
        <Route path='/contract' element={<ContractPage/>}/>
        <Route path='/about' element={<AboutUs/>}/>
        <Route path="*" element={<Page404 item="index"/>}/>
        {/* <Route path="*" element={<Index/>}/> */}
    </>);
    // const { getLocalSession } = PermissionService()
    // const activeSession = !!userPermissions;
    // const routePath = useLocation().pathname.split("/");
    // if (!this.activeSession) {
    //   return this.permissionService.getPermission().pipe(
    //     map((data) => {
    //       if (data.status) {
    //         if (data.data.user == undefined) {
    //           this.authService.logout()
    //           return false
    //         }
    //         this.userPermissions = data.data.user.permissions
    //         if (!this.userPermissions.admin.access) {
    //           delete this.userPermissions.admin
    //         }
    //         sessionStorage.setItem("user_access", JSON.stringify(this.userPermissions))

    //         if (this.userPermissions[this.routePath[1]]?.access) {
    //           return true
    //         } else if (this.userPermissions.client.access) {
    //           if (this.userPermissions.client.webMaster.access) {
    //             this.router.navigate(['client/webmaster'])
    //             return false
    //           } else if (this.userPermissions.client.partner.access) {
    //             this.router.navigate(['client/partner'])
    //             return false
    //           }
    //         } else if (this.userPermissions.admin.access) {
    //           this.router.navigate(['admin'])
    //           return false
    //         } else {
    //           this.authService.logout()
    //           return false
    //         }
    //       }
    //       this.authService.logout()
    //       return false
    //     }))
    // }

    // if (this.activeSession) {
    //   if (this.userPermissions[this.routePath[1]]?.access) {
    //     return true
    //   } else if (this.userPermissions.client.access) {
    //     if (this.userPermissions.client.webMaster.access) {
    //       this.router.navigate(['client/webmaster'])
    //       return false
    //     } else if (this.userPermissions.client.partner.access) {
    //       this.router.navigate(['client/partner'])
    //       return false
    //     }
    //   } else if (this.userPermissions.admin.access) {
    //     this.router.navigate(['admin'])
    //     return false
    //   } else {
    //     this.authService.logout()
    //     return false
    //   }
    // }

    if(auth.isAuthenticated){
        if(auth.permissions.admin.access){
            const admin = "/admin";
            return(
                <Routes>
                    {openRoutes}
                    <Route path={admin + '/products'} element={<AdminProducts/>}/>
                    <Route path={admin + '/productsGroups'} element={<AdminProductsGroups/>}/>
                    <Route path={admin + '/blog'} element={<Blog/>}/>
                    <Route path={admin + '/blog/form/:id'} element={<FormWrapper/>}/>
                    <Route path={admin + '/blog/form/'} element={<FormWrapper/>}/>
                    <Route path={admin + '/account'} element={<Account/>}/>
                    <Route path={admin + '/statuses'} element={<Statuses/>}/>
                    <Route path={admin + '/orders'} element={<OrdersAdmin/>}/>
                    <Route path={admin + '/orders/form/:id'} element={<OrdersFormWrapper/>}/>
                    <Route path={admin + '/orders/form/'} element={<OrdersFormWrapper/>}/>
                    <Route path={admin + '/roles'} element={<Roles/>}/>
                    <Route path={admin + '/roles/form'} element={<RolesFormWrapper/>}/>
                    <Route path={admin + '/roles/form/:id'} element={<RolesFormWrapper/>}/>
                    <Route path={admin + "/statistic"} element={<Statistic/>}/>
                    {/* <Route path={admin + '/gamefication'} element={<Gamefiacation/>}/> */}
                    <Route path={admin + '/wishlist'} element={<Wishlish/>}/>
                    <Route path={admin + '/categories'} element={<Categories/>}/>
                    <Route path={admin + '/discounts'} element={<Discounts/>}/>
                    <Route path={admin + '/brands'} element={<Brands/>}/>
                    <Route path={admin + '/users'} element={<Users/>}/>
                    <Route path={admin + '/carusels'} element={<CarouselSettings/>}/>
                    <Route path="*" element={<Page404 item="index"/>}/>
                </Routes>
            );
        }
        if(auth.permissions.customer.access){
            const user = "/user";
            return(
                <Routes>
                     {openRoutes}
                    <Route path={user +'/orders'} element={<OrdersCustomer/>}/>
                    <Route path={user +'/account'} element={<AccountClient/>}/>
                    <Route path={user + '/wishlist'} element={<WishlistClient/>}/>
                    <Route path="*" element={<Page404 item="index"/>}/>
                </Routes>
            );
        }
    }
    else{
        return(
            <Routes>
                 {openRoutes}
                <Route path='/auth' element={<Auth/>}/>
                <Route path='/auth/success' element={<RegistrationSuccess/>}/>
                <Route path="*" element={<Page404 item="index"/>}/>
            </Routes>
        );
    }
};
export default AppRoutes;
