import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { getAll } from "../../http/blogAPI";
import BlogCart from "../../components/Blog/BlogCart/BlogCart";
import styles from "./Blog.module.scss";
import Container from "../../components/Container/Container";
const Blog = () => {
    const [blogAll, setBlogAll] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        try {
            const allBlog = await getAll();
            if (allBlog.data.status) {
                setLoading(false);

                setBlogAll(allBlog.data.data);

            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };




    // const getMaxDepth = (str) => {
    //     const s = str.split('');
    //     console.log("🚀 ~ getMaxDepth ~ s:", s)
    //     let result = 0;
    //     s.forEach((el) => {
    //         if(el === "("){
    //             result++;
    //         }
    //     })
    //     console.log("🚀 ~ getMaxDepth ~ result:", result);
    // }     
    const getMaxDepth = (str) => {
        let maxDepth = 0;
        let currentDepth = 0;
    
        for (let char of str) {
            if (char === "(" || char === "{" || char === "[") {
                currentDepth++; // Збільшуємо глибину при відкритті дужки
                maxDepth = Math.max(maxDepth, currentDepth); // Оновлюємо максимальну глибину
            } else if (char === ")" || char === "}" || char === "]") {
                currentDepth--; // Зменшуємо глибину при закритті дужки
                if (currentDepth < 0) return -1; // Якщо закритих дужок більше, ніж відкритих, помилка
            }
        }
    
        // return currentDepth === 0 ? maxDepth : -1; // Якщо в кінці глибина не 0, значить помилка
        console.log("🚀 ~ getMaxDepth ~ maxDepth:", currentDepth === 0 ? maxDepth : -1);
        
    };



// const strings = '([(  )(]))';

    useEffect(() => {
        fetchData();
        // getMaxDepth(strings)
    }, []);

    // if (loading) {
    //     return <Loader />;
    // }

    return (
        <>
            <Container>
                    <h1 className={styles.pageName}>Блог</h1>
                    <div className={styles.pageTitleWrapper}><h2>Головні події</h2></div>
                    {blogAll.length > 0 ? (
                        <div className={styles.carts + " d-flex flex-wrap"}>
                            {blogAll.map((el, index) => {
                                return <BlogCart key={index} blogOne={el} />
                            })}
                        </div>
                    ) : (
                        <div>Подій немає</div>
                    )}
            </Container>
        </>
    );
};

export default Blog;
