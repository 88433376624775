import React, { useEffect, useState, useMemo, useCallback, useReducer } from "react";
import styles from "./Products.module.scss";
import ProductsCards from "../../components/ProductsCards/ProductsCard";
import { useParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getProductsOperation } from "../../store/products/operations";
import { getProductsSelector } from "../../store/products/selectors";
import { getFiltersProductsSelector } from "../../store/filters/selectors";
import { getCategoriesMenuSelector } from "../../store/categories/selectors";
import { filtersProductsOperation } from "../../store/filters/operations";
import ProductsFilter from "../../components/ProductsFilter/ProductsFilter";
import Crumbs from "../../components/Crumbs/Crumbs";

const Products = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const categoriesMenu = useSelector(getCategoriesMenuSelector);
    const filterProducts = useSelector(getFiltersProductsSelector);
    const getProducts = useSelector(getProductsSelector);

    const perPage = 30;
    const [selected, setSelected] = useState({});
    const [sort, setSort] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    // Тригер для оновлення даних
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    // Вибрана категорія
    const category = useMemo(() => {
        if (categoriesMenu.length > 0 && params?.categories) {
            return categoriesMenu.find((item) => item.title === params.categories) || {};
        }
        return {};
    }, [categoriesMenu, params.categories]);

    // Опції брендів
    const brands = useMemo(() => {
        if (filterProducts?.filters?.brand?.length > 0) {
            return filterProducts.filters.brand.map((brandArray) => (
                <option key={brandArray[0]._id} value={brandArray[0]._id}>
                    {brandArray[0].name}
                </option>
            ));
        }
        return [];
    }, [filterProducts]);

    // Фільтри та сортування
    const ordersFilter = useMemo(
        () => ({
            perPage,
            startPage,
            sort,
            categories: params.categories,
            filter: { ...selected },
        }),
        [perPage, startPage, sort, params.categories, selected]
    );

    // Сортування
    const sorting = useCallback((event) => {
        const updatedSort = event === "new" ? 1 : event;
        setSort(updatedSort);
        forceUpdate(); // Тригер перезавантаження даних
    }, []);

    // Фільтрація
    const filter = useCallback((key, event) => {
        setSelected((prevSelected) => {
            const updatedSelected = { ...prevSelected };
            if (!event) {
                delete updatedSelected[key];
            } else {
                updatedSelected[key] = event;
            }
            return updatedSelected;
        });
        forceUpdate(); // Тригер перезавантаження даних
    }, []);

    // Отримання продуктів
    useEffect(() => {
        if (params.categories) {
            dispatch(getProductsOperation(ordersFilter));
        }
    }, [dispatch, params.categories, ordersFilter]);

    // Отримання фільтрів
    useEffect(() => {
        if (params.categories) {
            dispatch(filtersProductsOperation(ordersFilter));
        }
    }, [dispatch, params.categories, ordersFilter]);

    // Встановлення продуктів і кількості сторінок
    useEffect(() => {
        if (getProducts) {
            const { products = [], totalProductsCount = 0 } = Array.isArray(getProducts)
                ? { products: getProducts, totalProductsCount: 0 }
                : getProducts;

            setProducts(products);
            setTotalPages(totalProductsCount);
        }
    }, [getProducts]);

    // Збереження фільтрів у LocalStorage
    useEffect(() => {
        localStorage.setItem("filtersProducts", JSON.stringify(ordersFilter));
    }, [ordersFilter]);

    // Завантаження фільтрів з LocalStorage
    useEffect(() => {
        const savedOrdersFilter = localStorage.getItem("filtersProducts");
        if (savedOrdersFilter) {
            const parsedFilters = JSON.parse(savedOrdersFilter);
            if (parsedFilters.categories !== params.categories) {
                setSelected({}); // Скидаємо фільтри при зміні категорії
            } else {
                setSelected(parsedFilters.filter || {});
            }
            setStartPage(1); // Скидаємо на першу сторінку
        }
    }, [params.categories]);

    return (
        <section>
            <Crumbs category={category} />
            <div className={`d-flex justify-content-between mb-3 ${styles.filter__block}`}>
                {/* {filterProducts && (
                    <ProductsFilter
                        filterProducts={filterProducts}
                        filter={filter}
                        selected={selected}
                        ordersFilter={ordersFilter}
                    />
                )} */}
                <div className={styles.select__wrapper}>
                    <select
                        name="brands"
                        id="brands"
                        value={ordersFilter?.filter?.brand || ""}
                        onChange={(e) => filter("brand", e.target.value)}
                    >
                        <option value="">Бренд</option>
                        {brands}
                    </select>
                </div>
                <div>
                    <div className={styles.filter}>
                        <select
                            name="sort"
                            id="sort"
                            value={sort}
                            onChange={(e) => sorting(e.target.value)}
                        >
                            <option value="new">Новинки</option>
                            <option value={-1}>За зменшенням ціни</option>
                            <option value={1}>За зростанням ціни</option>
                        </select>
                    </div>
                </div>
            </div>

            <ProductsCards
                className="cards__list"
                products={products}
                categoriesMenu={categoriesMenu}
            />
            <Pagination
                perPage={perPage}
                startPage={startPage}
                setPage={setStartPage}
                productsQuantity={totalPages}
            />
        </section>
    );
};

export default React.memo(Products);
