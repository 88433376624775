import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Це занадто коротке ім'я")
    .max(60, 'Мабуть, вас так не звуть')
    .required("Вкажіть ваше ім'я"),
  lastName: Yup.string()
    .min(2, 'Вибачте, це занадто коротке для прізвища')
    .max(60, 'Можливо, трохи скоротити?')
    .required('Вкажіть ваше прізвище'),
  phone: Yup.string()
    .matches(/^\+380\d{9}$/, 'Номер телефону, повинен бути у форматі +380XXXXXXXXX')
    .required('Вкажіть номер телефону'),
  email: Yup.string()
    .email('Невірна адреса електронної пошти')
    .required('Вкажіть електронну пошту'),

  // Валідація для об'єктів { value, label }
  region: Yup.mixed()
    .test(
      'is-object-with-label',
      'Оберіть область',
      (value) => value && typeof value === 'object' && 'label' in value && value.label.length >= 3
    )
    .required('Вкажіть область'),

  city: Yup.mixed()
    .test(
      'is-object-with-label',
      'Оберіть місто',
      (value) => value && typeof value === 'object' && 'label' in value && value.label.length >= 3
    )
    .required('Вкажіть місто'),

  delivery: Yup.mixed()
    .test(
      'is-object-with-label',
      'Оберіть спосіб доставки',
      (value) => value && typeof value === 'object' && 'label' in value && value.label.length >= 3
    )
    .required('Вкажіть спосіб доставки'),

  address: Yup.mixed()
    .test(
      'is-object-with-label',
      'Вкажіть адресу',
      (value) => value && typeof value === 'object' && 'label' in value && value.label.length >= 3
    )
    .required('Вкажіть адресу'),

  paymentMethod: Yup.string()
    .max(60, 'Макс. 60 літер')
    .required('Вкажіть спосіб оплати'),

  comment: Yup.string()
    .max(400, 'Макс. 400 літер'),
});

export default schema;
