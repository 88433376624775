import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
  getPostCity,
  getPostRegion,
  getPostDepartment,
} from "../../../http/newPostAPI";
import { deliveryMethodAction } from "../../../store/cart/actions";
import { deliveryMethodSelector } from "../../../store/cart/selectors";
import MySelect from "../../../components/MyForms/MySelect/MySelect"; // Використання оновленого MySelect
import styles from "./DeliveryDataInputs.module.scss";

const DeliveryDataInputs = () => {
  const { values, setFieldValue } = useFormikContext();
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const dispatch = useDispatch();
  const deliveryMethod = useSelector(deliveryMethodSelector);

  // Завантаження регіонів
  useEffect(() => {
    getPostRegion()
      .then((res) => {
        if (res?.data?.length) {
          setRegions(
            res.data.map((region) => ({
              value: region.Ref,
              label: region.Description,
            }))
          );
        } else {
          console.error("Regions data is empty:", res);
        }
      })
      .catch((error) => console.error("Error fetching regions:", error));
  }, []);

  // Автоматичне оновлення міст при виборі області
  useEffect(() => {
    if (values.region) {
      setCities([]);
      setDepartments([]);
      setFieldValue("city", "");
      setFieldValue("address", "");

      getPostCity(values.region.value)
        .then((res) => {
          if (res?.data?.length) {
            setCities(
              res.data.map((city) => ({
                value: city.Ref,
                label: city.Description,
              }))
            );
          } else {
            console.warn("No cities received for region:", values.region.label);
          }
        })
        .catch((error) => console.error("Error fetching cities:", error));
    }
  }, [values.region]);

  // Автоматичне оновлення відділень при виборі міста
  useEffect(() => {
    if (values.city) {
      setDepartments([]);
      setFieldValue("address", "");

      getPostDepartment(values.city.value)
        .then((res) => {
          if (res?.data?.length) {
            setDepartments(
              res.data.map((dep) => ({
                value: dep.Ref,
                label: dep.Description,
              }))
            );
          } else {
            console.warn("No departments received for city:", values.city.label);
          }
        })
        .catch((error) => console.error("Error fetching departments:", error));
    }
  }, [values.city]);

  return (
    <div>
      {/* Вибір способу доставки */}
      <MySelect
        label="Спосіб доставки"
        name="delivery"
        options={[
          { value: "Новою поштою", label: "Новою поштою" },
          // { value: "Самовивіз", label: "Самовивіз" },
        ]}
        onChange={(selectedOption) => dispatch(deliveryMethodAction(selectedOption.value))}
      />
      {/* Вибір області */}
      <MySelect
        label="Область"
        name="region"
        options={regions}
        onChange={(selectedOption) => setFieldValue("region", selectedOption)}
      />

      {/* Вибір міста */}
      <MySelect
        label="Місто"
        name="city"
        options={cities}
        isDisabled={!values.region}
        onChange={(selectedOption) => setFieldValue("city", selectedOption)}
      />

      {/* Вибір відділення */}
      <MySelect
        label="Відділення"
        name="address"
        options={departments}
        isDisabled={!values.city}
        onChange={(selectedOption) => setFieldValue("address", selectedOption)}
      />
    </div>
  );
};

export default DeliveryDataInputs;
